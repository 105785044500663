<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">image gallery: carousel</div>
        <div class="module-preview--contents">
            <div class="image-wrapper" v-for="(imageId, idx) in imageIds" :key="idx">
                <img :src="(imageId)" alt="">
            </div>            
        </div>
    </div>    
</template>

<script>
    import Widget from "@/mixins/Widget";
    import {mapGetters} from "vuex";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                imageIds: []
            }
        },
        computed: {
            ...mapGetters({
                getThumbnailUrlByFileId : 'files/getThumbnailUrlByFileId'
            })
        },
        created() {
            this.imageIds = JSON.parse(this.getValue('images'));
        }
    }
</script>
<style scoped>
    .module-preview--contents{
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));       
    }
    .module-preview--contents .image-wrapper{        
        height: 115px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }
</style>